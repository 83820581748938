<template>
    <div>
        <base-nav />
        <base-inner-banner img-type="smartCare"  />
            <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;">
                <div class="col-lg-12">
                    <h1 class="smart-Title page-h1 mb-0">智慧長照</h1>
                    <h3 class="slogan"> {{smartCare.slogan1}} </h3>
                    <h3 class="slogan"> {{smartCare.slogan2}} </h3>
                </div>
            </div>
            
            <div class="row" style="padding-bottom:63px;" >
                <h1 class="smart-Title page-h1 mb-0">{{smartCare.features.title}}</h1>
                <div class="col-lg-12">
                    <h3 class="slogan features-slogan"> {{smartCare.features.description}} </h3>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 features-point" >
                            <div class="sub-photo biological"></div>
                            <h1>{{smartCare.features.point1.title}}</h1>
                            <p>{{smartCare.features.point1.explanation}}</p>
                        </div>   
                        <div class="col-lg-4 col-md-4 col-sm-12 features-point">
                            <div class="sub-photo entry"></div>
                            <h1>{{smartCare.features.point2.title}}</h1>
                            <p>{{smartCare.features.point2.explanation}}</p>
                        </div>   
                        <div class="col-lg-4 col-md-4 col-sm-12 features-point">
                            <div class="sub-photo grade"></div>
                            <h1>{{smartCare.features.point3.title}}</h1>
                            <p>{{smartCare.features.point3.explanation}}</p>
                        </div>   

                    </div>
                </div>

            </div>

            <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;" >
                <h1 class="smart-Title page-h1 mb-0">{{smartCare.painPoint}}</h1>
                <div class="col-lg-12">
                    <div class="row pain-point-layout">
                        <div class="app-card col-lg-3 col-md-12 col-sm-12">
                            <h1 class="pain-point-title">{{smartCare.problem1.title}}</h1>
                            <ul class="pain-point-list">
                                <li>{{smartCare.problem1.explanation1}}</li>
                                <li>{{smartCare.problem1.explanation2}}</li>
                                <li>{{smartCare.problem1.explanation3}}</li>
                            </ul>  
                        </div>
                        <div class="app-card col-lg-3 col-md-12 col-sm-12">
                            <h1 class="pain-point-title">{{smartCare.problem2.title}}</h1>
                            <ul class="pain-point-list">
                                <li>{{smartCare.problem2.explanation1}}</li>
                                <li>{{smartCare.problem2.explanation2}}</li>
                                <li>{{smartCare.problem2.explanation3}}</li>
                                <li>{{smartCare.problem2.explanation4}}</li>
                            </ul>
                        </div>
                        <div class="app-card col-lg-3 col-md-12 col-sm-12">
                            <h1 class="pain-point-title">{{smartCare.problem3.title}}</h1>
                            <ul class="pain-point-list">
                                <li>{{smartCare.problem3.explanation1}}</li>
                                <li>{{smartCare.problem3.explanation2}}</li>
                                <li>{{smartCare.problem3.explanation3}}</li>
                                <li>{{smartCare.problem3.explanation4}}</li>
                            </ul>
                        </div>
                        <div class="app-card col-lg-3 col-md-12 col-sm-12">
                            <h1 class="pain-point-title">{{smartCare.problem4.title}}</h1>
                            <ul class="pain-point-list">
                                <li>{{smartCare.problem4.explanation1}}</li>
                                <li>{{smartCare.problem4.explanation2}}</li>
                            </ul>
                        </div>
                        <div class="app-card col-lg-3 col-md-12 col-sm-12">
                            <h1 class="pain-point-title">{{smartCare.problem5.title}}</h1>
                            <ul class="pain-point-list">
                                <li>{{smartCare.problem5.explanation1}}</li>
                                <li>{{smartCare.problem5.explanation2}}</li>
                                <li>{{smartCare.problem5.explanation3}}</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="row">
                <h1 class="smart-Title page-h1 mb-0">{{smartCare.solution.title}}</h1>
                <div class="col-lg-12">
                    <div class="row solution-row ">
                        <div class="col-lg-5 col-md-12 col-sm-12 solution-left">
                            <div class="solution-img-size">
                                <img class="solution-img-left" src="@/assets/img/usecase/airaFace-care.webp" alt="愛愛院智慧長照案例，使用airaFace人臉辨識系統，快速辨識進出人員，預防老人走失">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <h1 class="solution-title">{{smartCare.solution.product1.intro}}</h1>

                            <ul class="solution-points">
                                <li class="solution-list">{{smartCare.solution.product1.explanation1}}</li>
                                <li class="solution-list">{{smartCare.solution.product1.explanation2}}</li>
                                <li class="solution-list">{{smartCare.solution.product1.explanation3}}</li>
                                <li class="solution-list">{{smartCare.solution.product1.explanation4}}</li>
                                <li class="solution-list">{{smartCare.solution.product1.explanation5}}</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row solution-row ">
                        <div class="col-lg-5 col-md-12 col-sm-12 solution-left">
                            <div class="solution-img-size">
                                <img class="solution-img-left" src="@/assets/img/usecase/airaTablet-mini-care.webp" alt="airaTablet mini，5.5吋人臉辨識測溫平板，確保訪客配戴口罩才可進入，愛愛院智慧長照案例">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <h1 class="solution-title">{{smartCare.solution.product2.intro}}</h1>

                            <ul class="solution-points">
                                <li class="solution-list">{{smartCare.solution.product2.explanation1}}</li>
                                <li class="solution-list">{{smartCare.solution.product2.explanation2}}</li>
                                <li class="solution-list">{{smartCare.solution.product2.explanation3}}</li>
                                <li class="solution-list">{{smartCare.solution.product2.explanation4}}</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row solution-row " style="">
                        <div class="col-lg-5 col-md-12 col-sm-12 solution-left">
                            <div class="solution-img-size">
                                <img class="solution-img-left" src="@/assets/img/usecase/airaTrack-solution.webp" alt="airaTrack，人臉辨識追蹤系統">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <h1 class="solution-title">{{smartCare.solution.product3.intro}}</h1>

                            <ul class="solution-points">
                                <li class="solution-list">{{smartCare.solution.product3.explanation1}}</li>
                                <li class="solution-list">{{smartCare.solution.product3.explanation2}}</li>
                                <li class="solution-list">{{smartCare.solution.product3.explanation3}}</li>
                                <li class="solution-list">{{smartCare.solution.product3.explanation4}}</li>
                                <li class="solution-list">{{smartCare.solution.product3.explanation5}}</li>
                            </ul>

                        </div>
                        
                        
                    </div>
                </div>
            </div>

            <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;" >
                <h1 class="smart-Title page-h1 mb-0">成功案例影片</h1>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="solution-video1">
                                <iframe  width="560" height="315" :src="smartCare.demoVideo.video1.src" :title="smartCare.demoVideo.video1.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="solution-video2">
                                <iframe  width="560" height="315" :src="smartCare.demoVideo.video2.src" :title="smartCare.demoVideo.video2.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" style="padding-bottom:63px;" >
                <h1 class="smart-Title page-h1 mb-0">媒體報導</h1>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <div class="position-relative">  
                                <div class="swiper-container-events overflow-hidden position-relative w-100">
                                    <div class="swiper-wrapper">
                                        <a v-for="event in eventList"
                                            :key="event._id"
                                            class="swiper-slide"
                                            :href="`${event.url}`">
                                            <div  class="event-item position-relative overflow-hidden">
                                                <img
                                                    class="event-image"
                                                    :src="event.cover"
                                                    :alt="event.title"
                                                    >
                                                <h4 class="event-item-title">{{ event.title }}</h4>
                                                <div class="event-more">
                                                    <span>{{ $t('MoreEventDetail') }}</span>
                                                    <b-icon class="more-icon" icon="chevron-right"></b-icon>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <!-- dots -->
                                    <div class="swiper-pagination"></div>
                                </div>

                                <!-- navigation -->
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>

                                <div class="more-wrapper" >
                                    <BaseMore :lead-to="'/events'">
                                        {{ $t('MoreNews') }}
                                    </BaseMore> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                </div>

            </div>

            <div class="row" style="background-color:#f6f6f6;padding-bottom:80px;">
                <h1 class="smart-Title page-h1 mb-0">推薦產品</h1>
                <div class="col-lg-12">
                    <div class="row" >
                        <div class="col-lg-4 solution-product-left">
                            <div>
                                <div class="solution-product-img">
                                    <img class="img-size"  loading="lazy" 
                                    src="@/assets/img/product/airaTablet_mini.png" 
                                    :title="$t('Products.AiraTablet_mini.description')" 
                                    :alt="$t('Products.AiraTablet_mini.description')">
                                </div>
                                <div class="solution-product-text">
                                    <h1>airaTablet mini</h1>
                                    <p>5.5吋人臉辨識測溫平板</p>
                                    <BaseMore class="more" :lead-to="`/products`">
                                            {{ $t('MoreProductDetail') }}
                                    </BaseMore>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 solution-product-middle">
                                <div>
                                    <div class="solution-product-img">
                                        <img class="img-size"  loading="lazy" 
                                        src="@/assets/img/product/airaFace.png" 
                                        :title="$t('Products.AiraFace.description')"
                                        :alt="$t('Products.AiraFace.description')">
                                    </div>
                                    <div class="solution-product-text">
                                        <h1>airaFace</h1>
                                        <p>人臉辨識主機</p>
                                        <BaseMore class="more" :lead-to="`/products`">
                                            {{ $t('MoreProductDetail') }}
                                        </BaseMore>
                                    </div>
                                </div>
                            
                        </div> 
                        <div class="col-lg-4 solution-product-right">
                            <div>
                                <div class="solution-product-img">
                                    <img class="img-size"  loading="lazy" 
                                src="@/assets/img/product/airaTrack.png" 
                                :title="$t('Products.AiraTrack.description')"
                                :alt="$t('Products.AiraTrack.description')">
                                </div>
                                <div class="solution-product-text">
                                    <h1>airaTrack</h1>
                                    <p>全場域人員追蹤解決方案</p>
                                    <BaseMore class="more" :lead-to="`/products`">
                                            {{ $t('MoreProductDetail') }}
                                    </BaseMore>
                                </div>
                            </div>
                            
                        </div>     
                    </div>

                </div>

            </div>
            <div class="row" >
                <div class="col-lg-12">
                    <div style="text-align:center;height:300px">
                        <h1 class="smart-Title page-h1 mb-0">讓你的辦公場域更加安全</h1>
                        <router-link to="/contactUs" >
                            <button class="call-to-act">
                                聯絡我們
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>


        <base-footer />
    </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay, Lazy }  from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

const defaultState = () => {
    return {
        smartCare: {
            slogan1: `隨著台灣高齡化人口逐漸提高的趨勢，民眾對於長照的需求日漸劇增，但人力短缺一直是長照產業所面臨的問題。每當突然的意外發生或是院所內的老人走失，都對照顧中心增加不少負擔。`,
            slogan2:`aira使用人臉辨識應用作為主軸，將解決方案深入長照的各種管理問題，並提供最有效率以及彈性的智慧長照解決方案，讓智慧長照釋放長照中心緊繃的人力，並為被照顧者打造更加智能和安全的場域。`,
            features: {
                title:"特點",
                description:"如何有效管理人員考勤、健康和場域安全，是智慧長照必須考量的課題。以下三點正是構成智慧長照所需要的關鍵",
                point1:{
                    title:"自動化",
                    explanation:"人臉辨識系統節省大量人力"
                },
                point2:{
                    title:"區域管制",
                    explanation:"門禁管控保護老人安全"
                },
                point3:{
                    title:"系統整合",
                    explanation:"單一產品整合不同應用系統"
                },

            },
            painPoint: "挑戰",
            problem1:{
                title: "需要整合門禁系統與多支攝影機辨識",
                explanation1: "讓所有出入口都整合至系統進行管理，並與不同類型的門鎖進行整合(如閘道機、陽極鎖等等)",
                explanation2: "辨識系統串聯多台攝影機，每台攝影機都需要即時進行人臉辨識",
                explanation3: "人臉辨識技術須克服攝影機的高角度，以及環境光線變化等問題，並要能一次辨識多人",
                explanation4: "市面上的人臉辨識需系統辨識年長者不易有高準確，容易有誤判的情況產生"
            },
            problem2:{
                title: "人員考勤和住戶管理",
                explanation1: "防疫期間，須有專人確認是否量測體溫和偵測口罩配戴，並進行紀錄",
                explanation2: "老人住戶的行蹤須即時掌握，避免走失或是意外發生",
                explanation3: "人員進出和考勤紀錄，須要整合企業現有的人力資源系統",
                explanation4: "不同的區域需要有不同的人員權限，確保安全"
            },
            problem3:{
                title: "訪客實名制管理",
                explanation1: "人員出入複雜，保全無法快速識別身分",
                explanation2: "防疫期間需要確實量測與紀錄體溫，才可通行",
                explanation3: "訪客只能在特定時間進出，若超過拜訪時間，須有專人前去提醒或驅離",
                explanation4: "進出管制無法消化過多訪客，導致觀感不佳"
            },
            problem4:{
                title: "車輛管理",
                explanation1: "需專人管理停車場進出車輛與人員",
                explanation2: "需整合車輛辨識進訪客管理和教職員考勤系統"
            },
            problem5:{
                title: "大場域的安防系統",
                explanation1: "無法快速掌握嫌疑人和黑名單人員的動向，即時制止犯罪意圖 ",
                explanation2: "當有老人走失事件發生時，無法即時掌握動向",
                explanation3: "當有犯罪事件發生，需花大量時間調閱和剪輯監視畫面，以蒐集證據",
            },
            solution: {
                title: "解決方案",
                product1:{
                    intro:"airaFace：多功能的人臉辨識主機，滿足不同需求",
                    explanation1:"整合不同門禁裝置，使用安防攝影機即可進行人臉辨識",
                    explanation2:"克服高角度和環境光線變化問題，戴口罩、未戴口罩的人員都可以被正確識別，並可精準辨識老人",
                    explanation3:"可透過人臉辨識進行門禁管理、 考勤管理、訪客自助報到、 黑名單偵測及通知",
                    explanation4:"系統提供 Restful API ，可與現有校務系統進行整合，並可使用Email, HTTPS 以及 LINE即時通知",
                    explanation5:"可進行車牌辨識，並將出入資訊彙整到訪客管理與人員報到系統中，或是確認駕駛人員"
                },
                product2:{
                    intro:"airaTablet mini：輕巧簡便的門禁防疫方案",
                    explanation1:"人臉辨識系統可結合現有門鎖和閘道機進行人流控制",
                    explanation2:"支持口罩偵測和體溫量測，系統自動記錄體溫和通報高溫警報",
                    explanation3:"安裝容易，三步驟快速啟⽤，降低導入⾨檻",
                    explanation4:"提供員工考勤、訪客管理和門禁管理等功能，並可搭配airaFace使用",
                },
                product3:{
                    intro:"airaTrack：跨攝影機的全場域人臉追蹤系統",
                    explanation1:"不需要事先註冊人臉，即可進行跨攝影機人臉比對",
                    explanation2:"搜索VMS即時串流影像，一萬張人臉比對僅需1秒",
                    explanation3:"搜索VMS的錄像存檔，可節省83.3%的人工比對時間",
                    explanation4:"配戴口罩也可搜尋，讓可疑人物無所遁形",
                    explanation5:"搜索完成後的證據檔，可匯出為影片與PDF，或標註VMS書籤供進一步查詢"
                },
            },
            demoVideo:{
                video1:{
                    title: "airaFace人臉辨識系統，可以防範老人走失，內建點名系統、異常通知。台北市東明長照的智慧長照案例",
                    src: `https://www.youtube.com/embed/lm3vrmfpFKA`
                },
                video2:{
                    title: "巢向未來競賽- 專訪愛愛院秘書長，採用aira人臉辨識系統的智慧長照案例",
                    src: `https://www.youtube.com/embed/w0YeC9kbAWI`
                }
            },
            suggestProduct:{
                title:"推薦產品",
                product1:{
                    title:"airaTablet mini",
                    description:"5.5吋人臉辨識測溫平板",
                    imgUrl:`@/assets/img/product/airaTablet_mini.png`
                },
                product2:{
                    title:"airaFace",
                    description:"人臉辨識主機",
                    imgUrl:`@/assets/img/product/airaFace.png`
                },
                product3:{
                    title:"airaTrack",
                    description:"全場域人員追蹤解決方案",
                    imgUrl:`@/assets/img/product/airaTrack.png`
                }

            }

        },
        eventList: [
            {
                _id:"1",
                url:`/events/成功案例/愛愛院再佈署城智科技的臉辨方案於萬華總院，提升智慧照護服務`,
                cover: `https://www.aira.com.tw/images/bfce3ab8-d429-44eb-a33a-4eb4606d7de3.jpeg`,
                title: `愛愛院再佈署城智科技的臉辨方案於萬華總院，提升智慧照護服務`
            },
            {
                _id:"2",
                url:`/events/成功案例/東明住宿長照機構採用城智科技的臉辨方案，長照管理智能化`,
                cover: `https://www.aira.com.tw/images/b0d60322-867e-4004-945f-4eaf1c49731c.jpeg`,
                title: `東明住宿長照機構採用城智科技的臉辨方案，長照管理智能化`
            },
            {
                _id:"3",
                url:`/events/新聞/AIRA城智科技，榮獲"2021第十四屆%20創意狂想%20巢向未來-%20智慧化居住空間創意競賽"銀獎殊榮`,
                cover: `https://www.aira.com.tw/images/3b36827c-14c5-4720-b01d-93cfe232e645.jpeg`,
                title: `AIRA城智科技，榮獲"2021第十四屆 創意狂想 巢向未來- 智慧化居住空間創意競賽"銀獎殊榮`
            },
            {
                _id:"4",
                url:`/events/新聞/護遊民到做長照%20柯文哲表揚愛愛院施家`,
                cover: `https://www.aira.com.tw/images/2a728591-3822-4cbf-a68a-42aacd1e1ef9.jpeg`,
                title: `護遊民到做長照 柯文哲表揚愛愛院施家`
            },
            {
                _id:"5",
                url:`/events/秀展/The%20Cares%20Taipei%202021台北國際照護科技展圓滿結束`,
                cover: `https://www.aira.com.tw/images/f970aedd-78e6-4c44-99bd-4146a71f0364.jpeg`,
                title: `The Cares Taipei 2021台北國際照護科技展圓滿結束`
            }
        ],
        swiper_aira: null
    }
}
export default {
    name:'SmartCare',
    metaInfo() {
        return {
            title:this.$t('UseCases.Meta_Management.SmartCare.title'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('UseCases.Meta_Management.SmartCare.description')
                },
                {
                    name: 'keywords',
                    content: this.$t('UseCases.Meta_Management.SmartCare.description')
                },
                { property: "og:url", content: "https://www.aira.com.tw/useCases/smartCare",vmid: 'og:url'},
                { property: "og:image", content: "https://www.aira.com.tw/img/smart-care.75871831.webp",vmid: 'og:image'},
            ]
        }
    },
    data(){
        return Object.assign({}, defaultState())
    },
    created(){
        window.scrollTo(0,0);
    },
    mounted() {
        this.setSlide()
    },
    methods: {
        setSlide() {
            // eslint-disable-next-line
            this.swiper_aira = null;
            let swiper_aira;

            const swiperContainer = document.querySelector('.swiper-container-events');

            swiper_aira = new Swiper(swiperContainer, {
                slidesPerView: 1,
                spaceBetween: 6,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 870px
                    870: {
                        slidesPerView: 2.2,
                        spaceBetween: 16,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    }
                }
            });
        
            this.swiper_aira = swiper_aira;
        },
    }
}

</script>

<style lang="scss" scoped>

.swiper-button-next,
.swiper-button-prev {
    color: #d0d0d0;
    position: absolute;
    top: 50%;
}

.swiper-button-prev {
    left: -40px;
}

.swiper-button-next {
    right: -40px;
}

.features-slogan{
    margin-left: auto;
    margin-right: auto;
    width: 61%;
    font-size: 22px;
    line-height: 2.5rem;
    margin-bottom: 60px;
}

.features-point{
    text-align: center;
}

.sub-photo {
    width: auto;
    height: 60px;
    flex: 0 0 75px;
    object-fit: contain;
    opacity: 0.8;
    background-repeat: no-repeat;
    background-position: center;

    &.biological{
        background-image: url("~@/assets/img/solution/biological-2.png");
    }

    &.entry {
        background-image: url("~@/assets/img/solution/entry.png");
    }
    
    &.grade {
        background-image: url("~@/assets/img/solution/grade.png");
    }
}



.app-card {
    padding-top: 20px;
    background-color: #fff;
    line-height: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.smart-Title {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.slogan {
    margin-left: auto;
    margin-right: auto;
    width: 61%;
    font-size: 22px;
    line-height: 2.5rem;
}

.pain-point-layout {
    justify-content: center;
}

.pain-point-title {
    font-size: 24px;
    margin-left: 21px;
}

.solution-row {
    padding-bottom:60px;
}

.solution-list{
    font-size: 20px;
    margin-bottom: 20px;
}

.solution-points{
    margin-left: 6%;
}


.solution-img-size {
    position: relative;
    max-height: 400px;
    max-width: 400px;
    width: 100%;
}

.solution-img-left{
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.solution-title{
    margin-bottom: 30px;
    margin-left: 8%;
    font-size: 28px;
}

.solution-left{
    display: flex;
    justify-content: end;
}
.solution-right{
    display: flex;
    justify-content: start;
}

.solution-video1{
    display: flex;
    justify-content: right;
    margin-right: 30px;
}

.solution-video2{
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
}

.solution-product-left{
    display: flex;
    justify-content: end;
}

.solution-product-middle{
    display: flex;
    justify-content: center;
}

.solution-product-right{
    display: flex;
    justify-content: start;
}

.solution-product-img {
    position: relative;
    height: 300px;
    width: 300px;
}

.img-size{
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.solution-product-text{
    text-align: center;
}

.more-wrapper {
    text-align: center;
    margin-top: 15px;
}



.swiper-container-events{
    position: relative;
}

.event-image{
    object-fit: cover;
    object-position: center center;
    width: auto;
    min-width: 100%;
    height: 360px;
}

.event-item-title{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    color: #fff;
    background: rgba(0,0,0, 0.7);
    margin-bottom: 0;
    padding: 20px 20px;
    font-size: 20px;
    font-weight: 400;
}

.event-more {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -100%);
    opacity: 0;
    left: 50%;
    transition: transform 0.2s ease;
    z-index: 3;
    .more-icon {
        position: relative;
        left: 0;
        transition: left 0.1s ease;
    }
    &:hover {
        color: #fff;
        .more-icon {
            left: 6px;
        }
    }
}

.event-item {
    cursor: pointer;
    &:hover {
        .tab-title {
            color:  $color_primary;
        }
    }

    &:hover .event-more {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    &:after {
        content: '';
        display: block;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        transition: opacity 0.15s ease;
    }

    &:hover:after {
        opacity: 1;
    }
}

.swiper-slide{
    display: block;

    .event-item {
        display: inline-block;
        margin: 8px;
        width: 100%;
    }
}

.call-to-act {
    cursor: pointer;
    border: unset;
    background-color: #ff991a;
    color: #ffffff;
    height: 45px;
    width: 250px;
    border-radius:  5px;
    font-size: 30px;
    font-weight: 400px;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}

@media screen and (max-width: 950px) {
    .slogan{
        width: 77%;
        font-size: 20px;
        line-height: 3rem;
    }
    .innerBanner{
        height: 300px;
    }

    .solution-left {
        justify-content: center;
    }

    .solution-img-left{
        position: unset;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
    }

    .solution-img-size{
        width: unset;
    }

    .solution-title{
        margin-left: 40px;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 28px;
        padding-top: 40px;
    }

    .solution-points {
        margin-left: unset;
    }

    .solution-list {
        font-size: 20px;
        margin-bottom: 20px
    }

    .solution-video1{
        display: flex;
        justify-content: center;
        margin-right: unset;
        margin-bottom: 60px;
    }

    .solution-video2{
        display: flex;
        justify-content: center;
        margin-left: unset;
    }

    .solution-product-left{
        justify-content:center
    }
    .solution-product-right{
        justify-content:center
    }

    .event-more{
        display: none;
    }

    .event-item-title{
        padding: 15px 16px;
        font-size: 18px;
    }

    .swiper-slide{
        .event-item {
            margin: unset
        }
    }
}


@media screen and (max-width: 576px) {
    .innerBanner{
        height: 250px;
    }

    .slogan{
        line-height: 2rem;
        font-size: 18px;
    }
    .solution-list{
        font-size: 1rem;
    }
    .solution-title{
        font-size: 24px;
        margin-top: unset;
    }


    .event-item {
        &:hover {
            .tab-title {
                color:  transparent;
            }

            .active .tab-title{
                color: $color_primary;
            }
        }
    }
    .event-image{
        height: 300px;
    }

    .features-point{
        margin-bottom: 30px;
    }

}
</style>